import Color from 'color';
import _u from 'underscore';

class ColorSchemes {
  constructor() {
    this.schemes = [
      {name: "moon", start: Color('#475b6a'), end: Color('#afaca7')},
      {name: "grapefruit", start: Color('#ff4e50'), end: Color('#fab32e')},
      {name: "pacific", start: Color('#24556e'), end: Color('#71b2a2')},
      {name: "twilight", start: Color('#763dc2'), end: Color('#52ade7')},
      {name: "love", start: Color('#ff0844'), end: Color('#f49e84')},
      {name: "forest", start: Color('#3a656d'), end: Color('#85cc4b')},
      {name: "magenta", start: Color('#662D8C'), end: Color('#ff61a6')},
      {name: "desert", start: Color('#e9598f'), end: Color('#f9b523')}
    ];
  }
  getMixedColor(scheme, index, total) {
    var cs = _u.findWhere(this.schemes, {name: scheme});
    var total = (total == 1) ? total : total -1;
    var colorDiff = (index-total)/total*-1;
    return cs.start.mix(cs.end, colorDiff).string();
  }
  getScheme(name) {
    return _u.clone(_u.findWhere(this.schemes, {name: name}));
  }
  getAllSchemes(name) {
    if (name) {
      var selectedSchemes = _u.map(this.schemes, function(scheme){ 
        if (scheme.name == name) {
          scheme.selected = true;
        }
        else {
          scheme.selected = false;
        }
        return scheme;
      });
      return selectedSchemes;
    }
    else {
      return this.schemes;
    }
  }
}

export default ColorSchemes;
