import React from 'react';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import Textarea from 'react-textarea-autosize';
import {Link} from 'react-router';
import DocumentMeta from 'react-document-meta';
import { AddSymbolThin, RemoveSymbol, CheckSymbol, SortSymbol } from './Symbols';

class VoteExample extends React.Component {
  render() {
    return (
        <div className="sample-card ballot colorscheme-moon">
          <header>
            <h2>What appetizers should we serve at the party?</h2>
          </header>
          <span className="section-header">Your ranked selections (drag to arrange):</span>
          <ol className="ranked-candidates">
            <li className="panel ballot-item" style={{backgroundColor: "rgb(71, 91, 106)"}}>
              <div className="drag-indicator"><SortSymbol /></div>
              <span>Buffalo wings </span>
              <span className="light">6&nbsp;pts.</span>
              <div className="controls right">
                <span className="symbol">
                  <RemoveSymbol />
                </span>
              </div>
            </li>
            <li className="panel ballot-item" style={{backgroundColor: "rgb(123, 132, 137)"}}>
              <div className="drag-indicator"><SortSymbol /></div>
              <span>Guacamole </span>
              <span className="light">5&nbsp;pts.</span>
              <div className="controls right">
                <span className="symbol">
                  <RemoveSymbol />
                </span>
              </div>
            </li>
            <li className="panel empty" style={{backgroundColor: "rgba(175, 172, 167, 0.2)", borderTop: "2px dashed rgb(175, 172, 167)", borderBottom: "2px dashed rgb(175, 172, 167)", color: "rgb(175, 172, 167)"}}>
              <span>Add another below</span>
            </li>
          </ol>
          <span className="section-header">Additional options:</span>
          <ul className="unranked-candidates">
            <li className="panel">
              <div className="controls left">
                <span className="symbol">
                  <AddSymbolThin />
                </span>
              </div>
              Vegetable platter
            </li>
            <li className="panel">
              <div className="controls left">
                <span className="symbol">
                  <AddSymbolThin />
                </span>
              </div>
              Meatballs
            </li>
            <li className="panel">
              <div className="controls left">
                <span className="symbol">
                  <AddSymbolThin />
                </span>
              </div>
              Cheese board
            </li>
            <li className="panel">
              <div className="controls left">
                <span className="symbol">
                  <AddSymbolThin />
                </span>
              </div>
              Jalapeno poppers
            </li>
          </ul>
        </div>
    );
  }
}

class ResultsExample extends React.Component {
  render() {
    return (
      <div className="sample-results">
        <header><h2>What appetizers should we serve at the party?</h2></header>
        <section className="candidates">
        <div className="bar-graph">
        <div className="bar-section divisions-undefined" style={{width: "45.45454545454545%", backgroundColor: "rgb(71, 91, 106)"}}>
        </div>
        <div className="bar-section divisions-undefined" style={{width: "33.33333333333333%", backgroundColor: "rgb(123, 132, 137)"}}>
        </div>
        <div className="bar-section divisions-undefined" style={{width: "21.21212121212121%", backgroundColor: "rgb(175, 172, 167)"}}>
        </div>
        </div>
        <ul className="results">
        <li style={{ borderBottomColor: "rgba(71, 91, 106, 0.3)"}}>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "rgb(71, 91, 106)"}}>1</span>
        <p className="candidate">Guacamole</p>
        </div>
        <div className="percentage-container">
        <span className="percentage-bar" style={{ width: "100%", backgroundColor: "rgb(71, 91, 106)"}}>
        </span>
        </div>
        <span className="percent" style={{color: "rgb(71, 91, 106)"}}>
        <span>45</span>
        <span>%</span>
        </span>
        </li>
        <li style={{ borderBottomColor: "rgba(123, 132, 137, 0.3)"}}>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "rgb(123, 132, 137)"}}>2</span>
        <p className="candidate">Buffalo wings</p>
        </div>
        <div className="percentage-container">
        <span className="percentage-bar" style={{ width: "73.33333333333333%", backgroundColor: "rgb(123, 132, 137)"}}>
        </span>
        </div>
        <span className="percent" style={{ color: "rgb(123, 132, 137)"}}>
        <span>33</span>
        <span>%</span>
        </span>
        </li>
        <li style={{ borderBottomColor: "rgba(175, 172, 167, 0.3)"}}>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "rgb(175, 172, 167)"}}>3</span>
        <p className="candidate">Cheese board</p>
        </div>
        <div className="percentage-container">
        <span className="percentage-bar" style={{ width: "46.666666666666664%", backgroundColor: "rgb(175, 172, 167)"}}>
        </span>
        </div>
        <span className="percent" style={{ color: "rgb(175, 172, 167)"}}>
        <span>21</span>
        <span>%</span>
        </span>
        </li>
        <li style={{ borderBottomColor: "rgba(204, 204, 204, 0.3)"}} className="no-percentage">
        <div className="item">
        <span className="rank" style={{ backgroundColor: "#ccc"}}>&times;</span>
        <p className="candidate">Meatballs</p>
        </div>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "#ccc"}}>=</span>
        <p className="candidate">Jalapeno poppers</p>
        </div>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "#ccc"}}>=</span>
        <p className="candidate">Vegetable platter</p>
        </div>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "#ccc"}}>=</span>
        <p className="candidate">Potato skins</p>
        </div>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "#ccc"}}>=</span>
        <p className="candidate">Deviled eggs</p>
        </div>
        <div className="item">
        <span className="rank" style={{ backgroundColor: "#ccc"}}>=</span>
        <p className="candidate">Sliders</p>
        </div>
        <div className="percentage-container">
        <span className="percentage-bar" style={{ width: "0%", backgroundColor: "#ccc"}}>
        </span>
        </div>
        <span className="percent" style={{ color: "#ccc"}}>
        <span>0</span>
        <span>%</span>
        </span>
        </li>
        </ul>
        </section>
      </div>
    );
  }
}

<li class="panel empty" style="background-color: rgba(175, 172, 167, 0.2); border-top: 2px dashed rgb(249, 181, 35); border-bottom: 2px dashed rgb(249, 181, 35); color: rgb(249, 181, 35);" data-reactid=".cawwgijpos.2.2.0.0.2.1"><span data-reactid=".cawwgijpos.2.2.0.0.2.1.0">Add another below</span></li>

class Home extends React.Component {

  setFocus(event) {
    var el = event.currentTarget;
    while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
    el.classList.add('focused');
  }
  unsetFocus(event) {
    var el = event.currentTarget;
    while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
    el.classList.remove('focused');
  }
  handleSubmit(event) {
    event.preventDefault();
    var value = document.getElementById("quiry-description").querySelector("textarea").value;
    this.context.router.push({
      pathname: '/new',
      state: { value: value }
    })
  }

  render() {
    const meta = {
      title: 'Quiry - Ranked Polls',
      description: 'Survey smarter with ranked polls. Create free online polls. Share with your friends or family so they can vote for their favorite choices then see the results.',
      canonical: 'https://www.quiry.com',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'rank,ranked,vote,voting,poll,polls,survey,borda count,free,condorcet,winner,real time,results,consensus,best,top,choices,favorite,group,friends,family'
        }
      },
      auto: {
        ograph: true
      }
    };
    return (
      <div>
        <DocumentMeta {...meta} extend />
        <Header status={{
            pageTitle: 'Quiry',
            exceptionClass: '',
            exceptionMessage: ''
          }} color="moon"/>
        <main role="main" className="homepage colorscheme-moon">
          <section className="home-main">
            <div className="title-container">
              <h1 className="home-text-gradient">Survey smarter<br/>with ranked polls.</h1>
            </div>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <fieldset>
                <label id="quiry-description" className="textarea focus-parent primary">Your question:
                <Textarea
                  onFocus={this.setFocus}
                  onBlur={this.unsetFocus}
                  placeholder="What appetizers should we serve at the party?"
                  maxLength="300" />
                  <div className="underline"></div>
                  <div className="error-message"></div>
                </label>
              </fieldset>
              <button type='submit' className="panel button primary" style={{background: "#475b6a"}}>
                <div className="controls left">
                  <span className="symbol">
                    <CheckSymbol />
                  </span>
                </div>
                Create Your Poll
              </button>
            </form>
          </section>
          <section className="example-right">
            <div className="background"></div>
            <div className="container">
              <h2 className="header">Vote by ranking your favorite choices.</h2>
              <div className="example">
                <div className="overflow-container">
                  <VoteExample />
                </div>
              </div>
            </div>
          </section>
          <section className="example-left">
            <div className="background"></div>
            <div className="container">
              <h2 className="header">See the combined ranking of everyone's votes.</h2>
              <div className="example">
                <div className="overflow-container">
                  <ResultsExample />
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
Home.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Home;
