import React from 'react';
import {Link} from 'react-router';
import {QuiryLogo} from './Symbols';

class Navbar extends React.Component {
  render() {
    return (
      <header>
        <nav>
          <ul>
            <li className="logo">
              <Link to='/' className={"quiry-logo color-" + this.props.color}>
                <QuiryLogo />
              </Link>
            </li>
            <li>
              <Link to='/new'>Create a Quiry</Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Navbar;
