import React from 'react';
import {Link} from 'react-router';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <nav>
          <ul>
            <li>
              <Link to={'/terms'} className="">Terms</Link>
            </li>
            <li>
              <Link to={'/privacy'} className="">Privacy</Link>
            </li>
          </ul>
        </nav>
        {(() => {
          if (this.props.footnote) {
            return (
              <div className="footnote">
                {this.props.footnote}
              </div>
            );
          }
        })()}
      </footer>
    );
  }
}

export default Footer;
