import React from 'react';
import alt from '../alt';
import ReactDOM from 'react-dom';
import {Link} from 'react-router';
import DocumentMeta from 'react-document-meta';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import _u from 'underscore';
import BallotStore from '../stores/BallotStore';
import BallotActions from '../actions/BallotActions';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { AddSymbol,
  RemoveSymbol,
  SortSymbol,
  LeaderboardSymbol,
  CheckSymbol } from './Symbols';
import Color from 'color';
import ColorSchemes from '../modules/ColorSchemes';

class CandidateRemove extends React.Component {
  remove(event) {
    event.preventDefault();
    BallotActions.removeCandidate(event.currentTarget.getAttribute('data-id'));
  }
  render() {
    return (
      <span className="symbol" data-id={this.props.cId} onClick={this.remove}>
        <RemoveSymbol />
      </span>
    );
  }
}

const DragHandle = SortableHandle(() => <span className="drag"></span>); // This can be any component you want

const SortableItem = SortableElement(({color, candidate, totalRanked, totalCandidates}) => {
  var colorSchemes = new ColorSchemes();
  var bgColor = colorSchemes.getMixedColor(color, candidate.rank - 1, totalRanked);
  var ptVal = totalCandidates - candidate.rank + 1;
  var points = ptVal;
  points += (ptVal == 1) ? " pt." : " pts.";
  return (
    <li className="panel ballot-item" style={{ backgroundColor: bgColor }} data-id={candidate._id}>
      <DragHandle />
      <div className="drag-indicator">
        <SortSymbol />
      </div>
      {candidate.description} <span className="light">{points}</span>
      <div className="controls right">
        <CandidateRemove cId={candidate._id} />
      </div>
    </li>
  );
});
 
const SortableList = SortableContainer(({items, color, candidatesCount}) => {
  var colorSchemes = new ColorSchemes();
  var scheme = colorSchemes.getScheme(color)
  var itemColor = (items.length == 0) ? scheme.start : scheme.end;
  var itemTotal = (items.length == candidatesCount) ? items.length : items.length + 1;
    return (
        <ol className="ranked-candidates">
            {_u.sortBy(items, 'rank').map((candidate, index) =>
              <SortableItem key={`item-${index}`} index={index} color={color} candidate={candidate} totalRanked={itemTotal} totalCandidates={candidatesCount} />
            )}

            {(() => {
              if (items.length == 0) {
                return (
                  <li className="panel empty" style={{ backgroundColor: itemColor.fade(0.8), borderTop: "2px dashed " + itemColor, borderBottom: "2px dashed " + itemColor, color: itemColor}}><span>Add options below</span></li>
                )
              }
              else if (items.length != candidatesCount) {
                return (
                  <li className="panel empty" style={{ backgroundColor: itemColor.fade(0.8), borderTop: "2px dashed " + itemColor, borderBottom: "2px dashed " + itemColor, color: itemColor}}><span>Add another below</span></li>
                )
              }
            })()}

        </ol>
    );
});
 
class SortableComponent extends React.Component {
    onSortEnd({oldIndex, newIndex}){
      var dropPos = event.clientY;
      if (!dropPos) {
        dropPos = event.pageY;
      }
      var bottom = document.querySelector(".ranked-section").offsetHeight + 88;

      if (dropPos > bottom) {
        BallotActions.removeCandidate(event.target.parentElement.getAttribute('data-id'));
      }
      else {
        BallotActions.moveCandidate({old: oldIndex, new: newIndex});
      }

      document.querySelector(".removal").classList.remove("active");
      document.querySelector(".ballot").classList.remove("sorting");
    };
    onSortStart(event){
      document.querySelector(".ballot").classList.add("sorting");
    };
    onSortMove(event){
      var curPos = event.clientY;
      var touchDevice = false;
      if (!curPos) {
        curPos = event.pageY;
        touchDevice = true;
      }
      var bottom = document.querySelector(".ranked-section").offsetHeight + 88;
      if (!touchDevice) {
        if (curPos > bottom) {
          event.target.parentElement.classList.add("black");
        }
        else {
          event.target.parentElement.classList.remove("black");
        }
      }
    }
    render() {
        return (
            <SortableList items={this.props.items} color={this.props.color.name} candidatesCount={this.props.candidatesCount} onSortMove={this.onSortMove} onSortStart={this.onSortStart} onSortEnd={this.onSortEnd} useDragHandle={true} lockAxis={"y"} />
        )
    }
}

class RankedCandidate extends React.Component {
  removeCandidate(event) {
    event.preventDefault();
    BallotActions.removeCandidate(event.currentTarget.getAttribute('data-id'));
  }
  raiseCandidate(event) {
    event.preventDefault();
    var candidateId = event.currentTarget.getAttribute('data-id');
    var panel = event.currentTarget.parentNode.parentNode;
    var prev = panel.previousSibling;

    if (prev != null) {
      var panelColor = panel.style.backgroundColor;
      var prevColor = prev.style.backgroundColor;
      panel.classList.add("up");
      prev.classList.add("down");
      panel.setAttribute("style", "transform: translate(0px," + -(prev.offsetHeight + 4) + "px); background-color: " + prevColor + ";");
      prev.setAttribute("style", "transform: translate(0px," + (panel.offsetHeight + 4) + "px); background-color: " + panelColor + ";");

      window.setTimeout(function() {
        panel.classList.remove("up");
        prev.classList.remove("down");
        panel.setAttribute("style", "transform: none; background-color: " + panelColor + ";");
        prev.setAttribute("style", "transform: none; background-color: " + prevColor + ";");
        BallotActions.raiseCandidate(candidateId);
      }, 300);
    }
  }
  lowerCandidate(event) {
    event.preventDefault();
    var candidateId = event.currentTarget.getAttribute('data-id');
    var panel = event.currentTarget.parentNode.parentNode;
    var next = panel.nextSibling;

    if (next != null) {
      var panelColor = panel.style.backgroundColor;
      var nextColor = next.style.backgroundColor;
      panel.classList.add("down");
      next.classList.add("up");
      panel.setAttribute("style", "transform: translate(0px," + (next.offsetHeight + 4) + "px); background-color: " + nextColor + ";");
      next.setAttribute("style", "transform: translate(0px," + -(panel.offsetHeight + 4) + "px); background-color: " + panelColor + ";");

      window.setTimeout(function() {
        panel.classList.remove("down");
        next.classList.remove("up");
        panel.setAttribute("style", "transform: none; background-color: " + panelColor + ";");
        next.setAttribute("style", "transform: none; background-color: " + nextColor + ";");
        BallotActions.lowerCandidate(candidateId);
      }, 300);
    }
    else {
      BallotActions.lowerCandidate(candidateId);
    }
  }
  render() {
    var colorStart = Color('#ff4e50');
    var colorEnd = Color('#fab32e');
    var total = (this.props.total == 1) ? this.props.total : this.props.total -1;
    var index = this.props.candidate.rank - 1;
    var colorDiff = (index-total)/total*-1;
    var bgColor = colorStart.mix(colorEnd, colorDiff).string();
    return (
      <li className="panel before-show" style={{ backgroundColor: bgColor }}>
        <div className="controls left">
          <span className="symbol cross-symbol remove" data-id={this.props.candidate._id} onClick={this.removeCandidate}><i></i></span>
        </div>
        {this.props.candidate.description}
        <div className="controls right">
          <span className="symbol bold arrow-symbol up" data-id={this.props.candidate._id} onClick={this.raiseCandidate}><i></i></span>
          <span className="symbol bold arrow-symbol down" data-id={this.props.candidate._id} onClick={this.lowerCandidate}><i></i></span>
        </div>
      </li>
    );
  }

  componentDidMount() {
    // var el = ReactDOM.findDOMNode(this);
    // window.requestAnimationFrame(function() { 
    //   el.classList.remove('before-show');
    // });
  }
  
  componentWillUpdate() {
    // console.log("componentWillUpdate")
  }

  componentDidUpdate() {
    // console.log("componentDidUpdate")
  }
}

class UnrankedCandidate extends React.Component {
  addCandidate(event) {
    event.preventDefault();
    BallotActions.addCandidate(event.currentTarget.getAttribute('data-id'));
  }
  render() {
    return (
      <li className="panel" data-id={this.props.candidate._id} onClick={this.addCandidate}>
        <div className="controls left">
          <span className="symbol">
            <AddSymbol />
          </span>
        </div>
        {this.props.candidate.description}
      </li>
    );
  }
}

class ContextualBanner extends React.Component {
  render() {
    return (
      <div className={this.props.state ? "contextual-banner message-timeout" : "contextual-banner" }>            
            {(() => {
              if (this.props.state) {
                return (
                  <div className={this.props.state ? "message " + this.props.state.type : "" }>
                    {this.props.state ? this.props.state.message : ""}
                  </div>
                );
              }
            })()}
      </div>
    )
  }
}

class Ballot extends React.Component {

  constructor(props) {
    super(props);
    this.state = BallotStore.getState();
    this.onChange = this.onChange.bind(this);
    this.submitting = false;
  }

  componentDidMount() {
    BallotStore.listen(this.onChange);
    if (this.props.params.id != undefined) {
      if (this.props.route.path == "/ballot/:id") {
        BallotActions.getBallot(this.props.params.id);
      }
    }
    else if (this.props.route.path == "/vote/:token") {
      BallotActions.getQuiry(this.props.params.token);
    }
  }

  componentWillUnmount() {
    BallotStore.unlisten(this.onChange);
    alt.recycle(BallotStore);
  }

  setFocus(event) {
    var el = event.currentTarget;
    while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
    el.classList.add('focused');
  }
  unsetFocus(event) {
    var el = event.currentTarget;
    while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
    el.classList.remove('focused');
  }
  onChange(state) {
    this.setState(state);
  }

  checkLength(el, length) {
    var valid = true;
    if (length === 0) {
      el.classList.add('error');
      el.querySelector('.error-message').innerHTML = "Can't be blank";
      valid = false;
    }
    else if (length > 300) {
      el.classList.add('error');
      el.querySelector('.error-message').innerHTML = "Exceeds 35 character limit";
      valid = false;
    }
    return valid;
  }

  validateForm() {
    var valid = true;
    var name = document.getElementById('name-input');
    var nameContainer = name;
    while ((nameContainer = nameContainer.parentElement) && !nameContainer.classList.contains('focus-parent'));
    var nameLength = name.value.length;
    if (!this.checkLength(nameContainer, nameLength)) { valid = false }
    if (_u.where(this.state.candidates, {selected: true}).length == 0) { 
      valid = false;
      BallotActions.setStatus({exceptionClass: 'error', exceptionMessage: "You haven't made any selections"});
    }
    return valid;
  }

  submitVote(event) {
    event.preventDefault();
    if (!this.submitting) {
      this.submitting = true;
      if (this.validateForm()) {
        BallotActions.submitBallot({
          router: this.context.router,
          state: this.state
        });
      }
      else {
        this.submitting = false;
      }
    }
  }

  deleteBallot(event) {
    event.preventDefault();
    BallotActions.deleteBallot({ id: this.state.id, router: this.context.router });
  }

  render() {
    if (this.state._quiryToken) {
      const meta = (this.state.id) ? {
        title: 'Edit Vote - Quiry',
        description: 'Update your vote for this poll.',
        canonical: 'https://www.quiry.com/ballot/' + this.state.id,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'rank,ranked,vote,voting,poll,polls,survey,vote,opinion,favorite,decision,top,choices,best,update,edit'
          }
        },
        auto: {
          ograph: true
        }
      } : {
        title: this.state.description + ' - Vote - Quiry',
        description: 'Voice your opinion by ranking these choices: ' + _u.pluck(this.state.candidates, "description").join(" | "),
        canonical: 'https://www.quiry.com/vote/' + this.state._quiryToken,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'rank,ranked,vote,voting,poll,polls,survey,vote,opinion,favorite,decision,top,choices,best'
          }
        },
        auto: {
          ograph: true
        }
      };
      return (
        <div>
         <DocumentMeta {...meta} extend />
         <Header color={this.state.color.name} status={this.state.status} />
          <main role="main" className={"ballot colorscheme-" + this.state.color.name}>     
            <form>
              <section className="ranked-section">
                <header>
                  <h2>
                    {this.state.description}                
                  </h2>
                </header>

                <span className="section-header">Your ranked selections (drag to arrange):</span>

                <SortableComponent items={_u.sortBy(_u.where(this.state.candidates, {selected: true}), 'rank')} color={this.state.color} candidatesCount={this.state.candidates.length} />

                {(() => {
                  /*if (_u.where(this.state.candidates, {selected: true}).length == 0) {
                    return (
                      <ol className="ranked-candidates">
                        <li className="panel empty" style={{ backgroundColor: this.state.color.start.fade(0.85), borderTop: "2px dashed " + this.state.color.start, borderBottom: "2px dashed " + this.state.color.start}}><span></span></li>
                        <li className="panel empty" style={{ backgroundColor: this.state.color.end.fade(0.85), borderTop: "2px dashed " + this.state.color.end, borderBottom: "2px dashed " + this.state.color.end}}><span></span></li>
                      </ol>
                    );
                  }*/
                })()}
              </section>
              <section style={{ position: "relative"}}>
                <div className="removal panel empty"><span>Drop here to remove</span></div>

                <span className={(_u.where(this.state.candidates, {selected: false}).length == 0) ? "section-header disabled" : "section-header"}>Additional options:</span>

                <ul className="unranked-candidates">
                  {
                    _u.where(this.state.candidates, {selected: false}).map((candidate, index) => {
                      return <UnrankedCandidate candidate={candidate} key={index} />;
                    })
                  }
                </ul>
              </section>
              <section>
                <fieldset>
                  <div className="input focus-parent">
                    <label>Your name:</label>
                    <span className="input-wrapper"><input id="name-input" value={this.state.respondent} onFocus={this.setFocus} onBlur={this.unsetFocus} onChange={BallotActions.updaterespondent} /></span>
                    <div className="underline"></div>
                    <div className="error-message"></div>
                  </div>
                </fieldset>
              </section>
              <section className="last" >
                  {(() => {
                    if (this.state.id) {
                      return (
                        <button className="panel button primary" onClick={this.submitVote.bind(this)}>
                          <div className="controls left">
                            <span className="symbol">
                              <CheckSymbol />
                            </span>
                          </div>
                          Update your vote
                        </button>
                      );
                    }
                    else {
                      return (
                        <button className="panel button primary" onClick={this.submitVote.bind(this)}>
                          <div className="controls left">
                            <span className="symbol">
                              <CheckSymbol />
                            </span>
                          </div>
                          Cast your vote
                        </button>
                      );
                    }
                  })()}

                  <Link to={'/results/' + this.state._quiryToken} className="panel button secondary">
                    <div className="controls left">
                      <span className="symbol">
                        <LeaderboardSymbol />
                      </span>
                    </div>
                    View Results
                  </Link>

                  {(() => {
                    if (this.state.id) {
                      return (
                        <a href="#" className="panel button" data-id={this.state.id} onClick={this.deleteBallot.bind(this)}>
                          <div className="controls left">
                            <span className="symbol danger">
                              <RemoveSymbol />
                            </span>
                          </div>
                          Delete Vote
                        </a>
                      );
                    }
                  })()}
              </section>
            </form>
          </main>
          <Footer />
        </div>
      );
    }
    else {
      return (
        <div>Loading</div>
      );
    }
  }
}
Ballot.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Ballot;
