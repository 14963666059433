import alt from '../alt';
import _u from 'underscore';
import request from 'superagent';

class ResultsActions {
  constructor() {
    this.generateActions(
      'getQuirySuccess'
    );
  }

  getQuiry(quiryToken) {
    request
      .get('/api/quiries/' + quiryToken + '/results')
      .end(function(err, res){
        if (err || !res.ok) {
          console.log('Oh no! error');
        } else {
          this.actions.getQuirySuccess(res.body);
        }
      }.bind(this));
  }
}

export default alt.createActions(ResultsActions);
