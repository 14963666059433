import React from 'react';
import {Link} from 'react-router';
import alt from '../alt';
import QuiryManageStore from '../stores/QuiryManageStore';
import QuiryManageActions from '../actions/QuiryManageActions';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import DocumentMeta from 'react-document-meta';
import ColorSchemes from '../modules/ColorSchemes';
import _u from 'underscore';
import copy from 'copy-to-clipboard';
import Color from 'color';
import { CheckSymbol,
  EditSymbol,
  LeaderboardSymbol,
  SaveSymbol,
  FBLogo
  } from './Symbols';

class Item extends React.Component {
  render() {
    return (
      <div className="item">
        <span className="rank" style={{backgroundColor: this.props.color}}>{this.props.candidate.rank}</span>
        <p className="candidate">{this.props.candidate.description}</p>
      </div>
    );
  }
}

class InlineItem extends React.Component {
  render() {
    return (
      <div className="item">
        <span className="rank" style={{color: this.props.color}}>{this.props.preference}</span>
        <p className="candidate">{this.props.candidate.description}</p>
      </div>
    );
  }
}

class Rank extends React.Component {
  render() {
    var colorSchemes = new ColorSchemes();
    var state = QuiryManageStore.getState();
    var noVotes = this.props.rank[0].percent == 0;
    if (noVotes) {
      var bgColor = "#ccc";
    }
    else {
      var bgColor = colorSchemes.getMixedColor(this.props.color, this.props.num, this.props.total);
    }
    var lightBgColor = Color(bgColor).alpha(0.3);

    var percentageWidth = {
      width: (this.props.rank[0].percent / state.results[0][0].percent * 100) + "%",
      backgroundColor: bgColor
    };
    return (
      <li style={{borderBottomColor: lightBgColor}} className={(noVotes) ? "no-percentage" : ""}>
        {          
          this.props.rank.map((candidate, index) => {
            return <Item candidate={candidate} color={bgColor} key={index} />
          })          
        }
        <div className="percentage-container">
          <span className="percentage-bar" style={percentageWidth}></span>
        </div>
        <span className="percent" style={{color: bgColor}}>{Math.round(this.props.rank[0].percent)}%</span>
      </li>
    );
  }
}

class UnrankedCandidate extends React.Component {
  render() {
    return (
      <li>
        {this.props.candidate.description}
      </li>
    );
  }
}

class QuiryManage extends React.Component {

  constructor(props) {
    super(props);
    this.state = QuiryManageStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() { 
    QuiryManageStore.listen(this.onChange);
    if (this.props.params.id != undefined) {
      QuiryManageActions.getQuiry(this.props.params.id);
    }
    if (this.props.location.state) {
      this.state.status.exceptionClass = this.props.location.state.class;
      this.state.status.exceptionMessage = this.props.location.state.message;
      this.context.router.replace({
        pathname: '/manage/' + this.props.params.id,
        state: { class: "", message: "" }
      })
    }
  }

  componentWillUnmount() {
    QuiryManageStore.unlisten(this.onChange);
    alt.recycle(QuiryManageStore);
  }

  onChange(state) {
    this.setState(state);
  }

  selectInput(event) {
    var el = event.currentTarget;
    el.select();
  }

  copyLink(event) {
    event.preventDefault();
    var el = event.currentTarget;
    copy(el.href);
    el.classList.add("safe");
    setTimeout(function(){
      el.classList.remove("safe");
    }, 1000);
  }

  shareFB(event) {
    event.preventDefault();
    var el = event.currentTarget;
    FB.ui(
    {
      method: 'share',
      href: el.href
    }, function(response){});
  }

  render() {
    if (this.state.id) {
    const meta = {
      title: 'Manage Poll - Quiry',
      description: 'Share, update, and manage your poll.',
      canonical: 'https://www.quiry.com/manage/' + this.state.id,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'rank,ranked,vote,voting,poll,polls,survey,manage,update,share'
        }
      },
      auto: {
        ograph: true
      }
    };
    var totalWithPercent = _u.filter(this.state.results, function(result){return result[0].percent > 0}).length;

    return (
      <div>
        <DocumentMeta {...meta} extend />
        <Header color={this.state.color.name} status={this.state.status}/>
        <main role="main" className={"colorscheme-" + this.state.color.name}>
          <section>
            <header>
              <span className="section-header">Question:</span>
              <h2>{this.state.description}</h2>
            </header>
          </section>
          <section>
            <span className="section-header">Share:</span>
            <Link to={'/vote/' + this.state.token} className="panel button secondary fb" onClick={this.shareFB}>
              <div className="controls left">
                <span className="symbol">
                  <FBLogo />
                </span>
              </div>
              Share on Facebook
            </Link>
            <Link to={'/vote/' + this.state.token} className="panel button secondary" onClick={this.copyLink}>
              <div className="controls left">
                <span className="symbol">
                  <CheckSymbol />
                </span>
              </div>
              Copy Voting Link
            </Link>
            <Link to={'/results/' + this.state.token} className="panel button secondary" onClick={this.copyLink}>
              <div className="controls left">
                <span className="symbol">
                  <LeaderboardSymbol />
                </span>
              </div>
              Copy Results Link
            </Link>
          </section>
          <section className="padding">
            <span className="section-header">Results:</span>
            <ul className="results">
              {
                this.state.results.map((rank, index) => {
                  return <Rank rank={rank} num={index} color={this.state.color.name} total={totalWithPercent} key={index} />
                })
              }
            </ul>
          </section>
          <section className="padding">
            <span className="section-header">Stats:</span>
            <ul>
              <li>Poll created <strong>{this.state.created}</strong>.</li>
              <li>Poll last updated <strong>{this.state.updated}</strong>.</li>
              <li><strong>{this.state.respondents.length} {(this.state.respondents.length == 1) ? "vote" : "votes"}</strong> cast.</li>
              <li>Last vote cast <strong>{this.state.lastVoteTime}</strong>.</li>
            </ul>
          </section>
          <section className="padding" style={{display: 'none'}}>
            <span className="section-header">Settings:</span>
            <form className="settings-form">
              <label>
                <input type="checkbox" />
                Poll is open for voting
              </label>
              <label>
                <input type="checkbox" />
                Allow voters to see results
              </label>
              <button type="submit" className="button panel primary">
              <div className="controls left">
                <span className="symbol">
                  <SaveSymbol />
                </span>
              </div>Save Settings</button>              
            </form>
          </section>
          <section className="padding">
            <span className="section-header">More Actions:</span>
            <Link to={'/edit/' + this.state.id} className="panel button secondary">
              <div className="controls left">
                <span className="symbol">
                  <EditSymbol />
                </span>
              </div>
              Edit Poll Contents
            </Link>
            <Link to={'/vote/' + this.state.token} className="panel button secondary">
              <div className="controls left">
                <span className="symbol">
                  <CheckSymbol />
                </span>
              </div>
              Cast a Vote
            </Link>
            <Link to={'/results/' + this.state.token} className="panel button secondary">
              <div className="controls left">
                <span className="symbol">
                  <LeaderboardSymbol />
                </span>
              </div>
              Go to Results Page
            </Link>
          </section>  
        </main>
        <Footer footnote={"Results calculated using the Borda Count method."} />
      </div>
    );
    }
    else {
      return (
        <div>Loading</div>
      );
    }
  }
}
QuiryManage.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default QuiryManage;
