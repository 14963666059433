import React from 'react';
import {Route} from 'react-router';
import App from './components/App';
import Home from './components/Home';
import Quiry from './components/Quiry';
import QuiryManage from './components/QuiryManage';
import Ballot from './components/Ballot';
import Results from './components/Results';
import Terms from './components/Terms';
import Privacy from './components/Privacy';

export default (
  <Route component={App}>
    <Route path='/' component={Home} />
    <Route path='/new' component={Quiry} />
    <Route path='/edit/:id' component={Quiry} />
    <Route path='/manage/:id' component={QuiryManage} />
    <Route path='/vote/:token' component={Ballot} />
    <Route path='/ballot/:id' component={Ballot} />
    <Route path='/results/:token' component={Results} />
    <Route path='/terms' component={Terms} />
    <Route path='/privacy' component={Privacy} />
  </Route>
);
