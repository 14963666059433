import alt from '../alt';
import _u from 'underscore';
import request from 'superagent';

class BallotActions {
  constructor() {
    this.generateActions(
      'updaterespondent',
      'getQuirySuccess',
      'getBallotSuccess',
      'deleteBallotSuccess',
      'deleteBallotFail',
      'setStatus',
      'resetStatus'
    );
  }
  addCandidate(id) {
    this.dispatch(id);
  }
  removeCandidate(id) {
    this.dispatch(id);
  }
  raiseCandidate(id) {
    this.dispatch(id);
  }
  lowerCandidate(id) {
    this.dispatch(id);
  }
  moveCandidate(indicies) {
    this.dispatch(indicies);
  }
  getQuiry(quiryToken) {
    request
      .get('/api/quiry/' + quiryToken)
      .end(function(err, res){
        if (err || !res.ok) {
          console.log('Oh no! error');
        } else {
          this.actions.getQuirySuccess(res.body);
        }
      }.bind(this));
  }
  getBallot(ballotId) {
    request
      .get('/api/ballots/' + ballotId)
      .end(function(err, res){
        if (err || !res.ok) {
          console.log('Oh no! error');
        } else {
          this.actions.getBallotSuccess(res.body);
        }
      }.bind(this));
  }
  submitBallot(ballotInput) {
    var selectionsArray = [];
     _u.where(ballotInput.state.candidates, {selected: true}).forEach((candidate) => {
      selectionsArray.push({preference: candidate.rank, _candidate: candidate._id});
    });
    var ballotData = { quiryId: ballotInput.state._quiry, respondent: ballotInput.state.respondent, selections: selectionsArray };

    var requestType = (ballotInput.state.id == undefined ? 'POST' : 'PUT');
    var requestURL = (ballotInput.state.id == undefined ? '/api/quiries/' + ballotInput.state._quiryToken + '/ballots' : '/api/ballots/' + ballotInput.state.id);
    var successMessage = (ballotInput.state.id == undefined ? 'Vote counted' : 'Vote updated');

    request(requestType, requestURL)
      .send(ballotData)
      .end(function(err, res){
        console.log(res.body);
        if (err || !res.ok) {
          console.log(res.body);
        } else {
          ballotInput.router.push({
            pathname: '/results/' + res.body.data._quiryToken,
            state: { class: "success", message: successMessage }
          })
        }
      }.bind(this));
  }
  deleteBallot(payload) {
    if (window.confirm("Are you sure you want to delete this vote?")) {
      request
        .del('/api/ballots/' + payload.id)
        .end(function(err, res){
          if (err || !res.ok) {
            this.actions.deleteBallotFail(res.body);
          } else {
            this.actions.deleteBallotSuccess({data: res.body, router: payload.router});
          }
        }.bind(this));
    }
  }
}

export default alt.createActions(BallotActions);
