import React from 'react';
import {Link} from 'react-router';
import {QuiryLogo} from './Symbols';

class Header extends React.Component {
  componentWillReceiveProps() {
  }
  render() {
    return (
      <header className={"main-header colorscheme-" + this.props.color}>
        <nav>
          <ul>
            {(() => {
              if (this.props.status.pageTitle != "Quiry") {
                return (
                  <li>
                    <Link to='/'>
                      Quiry
                    </Link>
                  </li>
                );
              }
            })()}
            <li>
              <Link to='/new'>
                Create a poll
              </Link>
            </li>
          </ul>
        </nav>
        <h1>{this.props.status.pageTitle}</h1>
        {(() => {
          if (this.props.status.exceptionMessage != "") {
            return (
              <div className={"message " + this.props.status.exceptionClass }>
                {this.props.status.exceptionMessage}
              </div>
            );
          }
        })()}
      </header>
    )
  }
}

export default Header;
