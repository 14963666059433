import React from 'react';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import Textarea from 'react-textarea-autosize';
import {Link} from 'react-router';
import DocumentMeta from 'react-document-meta';
import { AddSymbolThin, RemoveSymbol, CheckSymbol } from './Symbols';

class Home extends React.Component {
  render() {
    const meta = {
      title: 'Terms of Service - Quiry',
      description: 'Terms of Service for Quiry',
      canonical: 'https://www.quiry.com/terms',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'terms,service,rank,ranked,vote,voting,poll,polls,survey,borda count,free,condorcet,winner,real time,results,consensus,best,top,choices,favorite,group,friends,family'
        }
      },
      auto: {
        ograph: true
      }
    };
    return (
      <div>
        <DocumentMeta {...meta} extend />
        <Header status={{
            pageTitle: 'Quiry Terms',
            exceptionClass: '',
            exceptionMessage: ''
          }} color="desert"/>
        <main role="main" className="legal">
          <section>
            <h1>Terms of Service</h1>
            <p>By accessing and using this website and it’s services (Quiry) you agree to the terms of this agreement and our <Link to={'/privacy'} className="">Privacy Policy</Link>. Any participation in this site will constitute acceptance of this agreement. If you do not agree to these terms please do not use this site.</p>
            <h2>Who can use Quiry</h2>
            <p>Anyone 13 years of age or older who agrees with these terms may use Quiry.</p>
            <h2>Content</h2>
            <p>You retain all rights in, and are solely responsible for, the content you create with Quiry</p>
            <p>Quiry has the right to delete or modify any content on the site for any reason</p>
            <p>If you provide feedback to Quiry you agree that we can use it to improve Quiry without restriction or compensation to you</p>
            <h2>Security</h2>
            <p>Quiry does not provide security. Polls created with Quiry, and responses submitted to Quiry, are publically accessible. For more information see our <Link to={'/privacy'} className="">Privacy Policy</Link>.</p>
            <h2>Disclaimers</h2>
            <p>Quiry is currently in beta.</p>
            <p>Quiry and its content are provided on an “as is” basis. We do not guarantee our services will be meet your expectations, or be provided without errors or downtime.</p>
            <p>Quiry is offered for informational purposes only. Quiry is not responsible or liable for the accuracy, usefulness or availability of any information presented on the site.</p>
            <p>Quiry uses third parties to provide its services (such as hosting, networking, storage, etc.).</p>
            <p>These terms can be changed at anytime without notice.</p>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
Home.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Home;
