import React from 'react';
import DocumentMeta from 'react-document-meta';
var GoogleAnalytics = require('react-g-analytics');

class App extends React.Component {
  render() {
    const meta = {
      title: 'Quiry - Ranked Voting',
      description: 'Quiry helps groups make decisions with ranked voting.',
      canonical: 'https://www.quiry.com',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'quiry,ranked,voting,poll,survey'
        }
      }
    };
    return (
      <div>
        <DocumentMeta {...meta} />
        <GoogleAnalytics id="UA-98877992-1" />
        {this.props.children}
      </div>
    );
  }
}

export default App;
