import alt from '../alt';
import _u from 'underscore';
import BallotActions from '../actions/BallotActions';
import Color from 'color';
import ColorSchemes from '../modules/ColorSchemes';

class BallotStore {
  constructor() {
    this.colorSchemes = new ColorSchemes();
    this.bindActions(BallotActions);
    this.description = '';
    this.candidates = [];
    this.respondent = '';
    this._quiryToken = undefined;
    this.id = undefined;
    this.color = this.colorSchemes.getScheme("moon");
    this.status = {
      pageTitle: 'Vote',
      exceptionClass: '',
      exceptionMessage: ''
    };

    this.bindListeners({
      addCandidate: BallotActions.ADD_CANDIDATE,
      removeCandidate: BallotActions.REMOVE_CANDIDATE,
      raiseCandidate: BallotActions.RAISE_CANDIDATE,
      lowerCandidate: BallotActions.LOWER_CANDIDATE
    });
  }

  onUpdaterespondent(event) {
    // console.log(event.target.value);
    this.respondent = event.target.value;
    if ((this.respondent.length > 0)&&(this.description.length <= 35)) {
      var el = event.target;
      while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
      el.classList.remove('error');
    }
  }

  addCandidate(selectedId) {
    var numSelected = _u.where(this.candidates, {selected: true}).length;
    var candidate = _u.findWhere(this.candidates, {_id: selectedId})
    candidate.selected = true;
    candidate.rank = numSelected+1;
  }
  removeCandidate(removeId) {
    var removedCandidate = _u.findWhere(this.candidates, {_id: removeId});
    var removedRank = removedCandidate.rank;
    removedCandidate.rank = 0;
    removedCandidate.selected = false;
    _u.each(this.candidates, function(candidate, i){
      if (candidate.rank > removedRank) {
        candidate.rank -= 1;
      }
    });
  }
  raiseCandidate(Id) {
    var candidate = _u.findWhere(this.candidates, {_id: Id});
    var curRank = candidate.rank;
    if (curRank > 1) {
      _u.findWhere(this.candidates, {rank: (curRank-1) }).rank = curRank;
      candidate.rank = curRank - 1;
    }
  }
  lowerCandidate(Id) {
    var candidate = _u.findWhere(this.candidates, {_id: Id});
    var curRank = candidate.rank;
    if (curRank == _u.where(this.candidates, {selected: true}).length) {
      candidate.selected = false;
      candidate.rank = 0;
    }
    else {
      _u.findWhere(this.candidates, {rank: (curRank+1) }).rank = curRank;
      candidate.rank = curRank + 1;
    }
  }
  moveCandidate(indicies) {
    var rold = indicies.old + 1;
    var rnew = indicies.new + 1;
    if (rold < rnew) {
      _u.each(this.candidates, function(candidate, i){
        if ((candidate.rank > rold)&&(candidate.rank <= rnew)) {
          candidate.rank -= 1;
        }
        else if (candidate.rank == rold) {
          candidate.rank = rnew;
        }
      });
    }
    else if (rold > rnew) {
      _u.each(this.candidates, function(candidate, i){
        if ((candidate.rank < rold)&&(candidate.rank >= rnew)) {
          candidate.rank += 1;
        }
        else if (candidate.rank == rold) {
          candidate.rank = rnew;
        }
      });
    }
  }
  resetStatus(status) {
    this.status.exceptionClass = "";
    this.status.exceptionMessage = "";
    var bar = document.querySelector(".info-banner");
    bar.classList.remove("grow");
  }
  setStatus(status) {
    var bar = document.querySelector(".info-banner");
    bar.classList.add("grow");
    this.status.exceptionClass = status.exceptionClass;
    this.status.exceptionMessage = status.exceptionMessage;
    setTimeout(function(){ BallotActions.resetStatus() }, 3600);
  }
  onGetBallotSuccess(data) {
    if (!this.colorSchemes.getAllSchemes) {
      this.colorSchemes = new ColorSchemes();
    }
    var candidatesIndex = data.data.quiry.candidates;
    var colorS = this.colorSchemes.getScheme(data.data.quiry.colorscheme);
    candidatesIndex.map((candidate, index) => {
        candidate.rank = 0;
        candidate.selected = false;
        return candidate;
      });
    this.id = data.data.ballot._id;
    this._quiryToken = data.data.quiry.token;
    this.description = data.data.quiry.description;
    if (colorS) {
      this.color = colorS;
    }
    this.respondent = data.data.ballot.respondent;
    this.candidates = _u.shuffle(candidatesIndex);

    _u.each(data.data.ballot.selections, function(selection, i){
      var candidateMatch = _u.findWhere(this.candidates, {_id: selection._candidate._id});
      candidateMatch.selected = true;
      candidateMatch.rank = selection.preference;
    }, this);

  }

  onGetQuirySuccess(data) {
    if (!this.colorSchemes.getAllSchemes) {
      this.colorSchemes = new ColorSchemes();
    }
    var colorS = this.colorSchemes.getScheme(data.data.colorscheme);
    var candidatesIndex = data.data.candidates;
    candidatesIndex.map((candidate, index) => {
        candidate.rank = 0;
        candidate.selected = false;
        return candidate;
      });

    this._quiryToken = data.data.token;
    this.description = data.data.description;
    this.candidates = _u.shuffle(candidatesIndex);
    if (colorS) {
      this.color = colorS;
    }
  }

  onDeleteBallotSuccess(payload) {
    console.log(payload)
    payload.router.push({
      pathname: '/results/' + this._quiryToken,
      state: { class: "success", message: "Vote deleted" }
    })
  }

  onDeleteBallotFail(data) {
    this.setStatus({exceptionClass: 'error', exceptionMessage: 'Error when trying to delete'});
  }

}

export default alt.createStore(BallotStore);
