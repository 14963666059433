import alt from '../alt';
import request from 'superagent';

class QuiryManageActions {
  constructor() {
    this.generateActions(
      'getQuirySuccess'
    );
  }
  getQuiry(quiryId) {
    request
      .get('/api/quirieswithresults/' + quiryId)
      .end(function(err, res){
        if (err || !res.ok) {
          console.log('Oh no! error');
        } else {
          this.actions.getQuirySuccess(res.body);
        }
      }.bind(this));
  }

}

export default alt.createActions(QuiryManageActions);
