import React from 'react';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import Textarea from 'react-textarea-autosize';
import {Link} from 'react-router';
import DocumentMeta from 'react-document-meta';
import { AddSymbolThin, RemoveSymbol, CheckSymbol } from './Symbols';

class Home extends React.Component {
  render() {
    const meta = {
      title: 'Privacy Policy - Quiry',
      description: 'Privacy Policy for Quiry',
      canonical: 'https://www.quiry.com/privacy',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'privacy,policy,rank,ranked,vote,voting,poll,polls,survey,borda count,free,condorcet,winner,real time,results,consensus,best,top,choices,favorite,group,friends,family'
        }
      },
      auto: {
        ograph: true
      }
    };
    return (
      <div>
        <DocumentMeta {...meta} extend />
        <Header status={{
            pageTitle: 'Quiry Privacy',
            exceptionClass: '',
            exceptionMessage: ''
          }} color="desert"/>
        <main role="main" className="legal">
          <section>
            <h1>Privacy Policy</h1>
            <h2>How we collect information</h2>

            <p><strong>Information you provide directly:</strong>
            When you create a poll, or vote on a poll, using Quiry you voluntarily provide us with certain information.</p>
            <p><strong>Technical information when you use our product:</strong>
            We use third-party software to collect analytics data when you visit Quiry. This information can include the web pages you’ve visited, the date and time of your request, what type of device you’re using, etc.</p>

            <h2>How we use the information we collect</h2>

            <p>We use the information we collect to provide you with our poll services, for providing others with the ability to vote on your poll, for collecting your votes on polls, and calculating the results of polls.</p>

            <p>If you choose to provide your email address we may use it to send you alerts about the polls you’ve created.</p>

            <p>We also use analytics data and feedback to make improvements to Quiry by fixing bugs or adding features.</p>

            <h2>Transferring your information</h2>

            <p>By using Quiry you authorize us to transfer and store your information using third-party hosting and storage services.</p>

            <h2>Managing your information</h2>

            <p>When you create a poll with Quiry we provide a link for you to manage your poll. It follows this format: “https://www.quiry.com/manage/[quiry-id]”. Please save this link, as it is the only way for you to view, share, and make changes to your poll. If you provide an email address when creating the poll we will attempt to send you an email with this link as well. Also be aware anyone with who has this link can make changes to your poll. Quiry currently does not offer security for this.</p>

            <h2>How we share information</h2>

            <p>Anyone can potentially see polls you create, or votes you submit on Quiry. We do not actively share this information but it can be shared or discovered by others.</p>

            <p>We prevent search engines from indexing polls on our site with a “noindex” tag but can’t guarantee all search engines will comply.</p>

            <p>We use third-parties to run Quiry, so information you provide may be shared with those entities.</p>

            <p>This policy can be changed at anytime without notice.</p>

          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
Home.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Home;
