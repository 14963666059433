import alt from '../alt';
import QuiryActions from '../actions/QuiryActions';
import Color from 'color';
import ColorSchemes from '../modules/ColorSchemes';

class QuiryStore {
  constructor() {
    this.colorSchemes = new ColorSchemes();
    this.bindActions(QuiryActions);
    this.id = '';
    this.description = '';
    this.candidates = [{id: 0, description: "" },{id: 1, description: "" }];
    this.email = '';
    this.hasVotes = false;
    this.colors = this.colorSchemes.getAllSchemes("moon");
    this.color = this.colorSchemes.getScheme("moon");
    this.status = {
      pageTitle: 'New Poll',
      exceptionClass: '',
      exceptionMessage: '',
      notFound: false
    };
    this.edited = false;

    this.bindListeners({
      rmCandidate: QuiryActions.REMOVE_CANDIDATE,
      addCandidate: QuiryActions.ADD_CANDIDATE,
      setDescription: QuiryActions.SET_DESCRIPTION
    });
  }

  setDescription(value) {
    this.description = value;
  }

  onUpdateDescription(event) {
    this.edited = true;
    this.description = event.target.value;
    if ((this.description.length > 0)&&(this.description.length <= 300)) {
      var el = event.target;
      while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
      el.classList.remove('error');
    }
  }

  onUpdateCandidate(event) {
    this.edited = true;
    var index = event.target.getAttribute('data-id');
    this.candidates[index].description = event.target.value;
    if ((this.candidates[index].description.length > 0)&&(this.candidates[index].description.length <= 300)) {
      var el = event.target;
      el.parentElement.classList.remove('error');
    }
  }

  onUpdateEmail(event) {
    this.edited = true;
    this.email = event.target.value;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valid = re.test(this.email);
    if (valid) {
      var el = event.target;
      while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
      el.classList.remove('error');
    }
  }

  addCandidate() {
    this.edited = true;
    this.candidates.push({id: this.candidates.length, description: ""});
  }

  rmCandidate(rmIndex){
    this.edited = true;
    if (this.candidates.length > 2) {
      this.candidates.splice(rmIndex, 1);
      this.candidates.map((candidate, index) => {
        candidate.id = index;
        return candidate;
      });
    }
    else {
      this.candidates[rmIndex].description = "";
    }
  }

  setColorScheme(selectedScheme) {
    this.edited = true;
    this.colors.map((scheme, index) => {
      if (scheme.name == selectedScheme) {
        this.color.start = scheme.start;
        this.color.end = scheme.end;
        this.color.name = scheme.name;
        scheme.selected = true;
      }
      else {
        scheme.selected = false;
      }
    });
  }

  resetStatus(status) {
    this.status.exceptionClass = "";
    this.status.exceptionMessage = "";
  }

  setStatus(status) {
    if (status.notFound) {
      this.status.notFound = true;
      this.status.pageTitle = "Poll Not Found";
    }
    else {
      this.status.exceptionClass = status.exceptionClass;
      this.status.exceptionMessage = status.exceptionMessage;
      setTimeout(function(){ QuiryActions.resetStatus() }, 3600);
    }
  }

  onGetQuirySuccess(data) {
    if (!this.colorSchemes.getAllSchemes) {
      this.colorSchemes = new ColorSchemes();
    }
    var candidatesIndex = data.data.candidates;
    var percentTotals = 0;
    var colorS = this.colorSchemes.getScheme(data.data.colorscheme)
    candidatesIndex.map((candidate, index) => {
      candidate.id = index;
      percentTotals += candidate.percent;
      return candidate;
    });
    if (percentTotals > 0) {
      this.hasVotes = true;
    }    
    this.id = data.data._id;
    this.description = data.data.description;
    this.email = data.data.email;
    this.candidates = candidatesIndex;
    this.colors = this.colorSchemes.getAllSchemes(data.data.colorscheme);
    if (colorS) {
      this.color = colorS;
    }
    this.status.pageTitle = "Edit Poll";
  }

  onGetQuiryFail(data) {
    this.setStatus({notFound: true});
  }

  onPostQuirySuccess(payload) {
    payload.router.push({
      pathname: '/manage/' + payload.data._id,
      state: { class: "success", message: "Poll created" }
    })    
  }

  onPutQuirySuccess(payload) {
    this.setStatus({exceptionClass: 'success', exceptionMessage: 'Poll updated'});
    this.edited = false;
  }

  onPostQuiryFail(data) {
    this.setStatus({exceptionClass: 'error', exceptionMessage: 'Error when trying to save'});
  }

  onDeleteQuirySuccess(payload) {
    alt.recycle(this);
    this.setStatus({exceptionClass: 'success', exceptionMessage: 'Poll deleted'});
    payload.router.replace({
      pathname: '/new'
    })
  }

  onDeleteQuiryFail(data) {
    this.setStatus({exceptionClass: 'error', exceptionMessage: 'Error when trying to delete'});
  }

}

export default alt.createStore(QuiryStore);
