import React from 'react';
import Collapse, { Panel } from 'rc-collapse';
import alt from '../alt';
import {Link} from 'react-router';
import _u from 'underscore';
import ResultsStore from '../stores/ResultsStore';
import ResultsActions from '../actions/ResultsActions';
import Color from 'color';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import DocumentMeta from 'react-document-meta';
import ColorSchemes from '../modules/ColorSchemes';
import { EditSymbol,
  CheckSymbol, AddSymbol } from './Symbols';

class Item extends React.Component {
  render() {
    return (
      <div className="item">
        <span className="rank" style={{backgroundColor: this.props.color}}>{this.props.candidate.rank}</span>
        <p className="candidate">{this.props.candidate.description}</p>
      </div>
    );
  }
}

class InlineItem extends React.Component {
  render() {
    return (
      <div className="item">
        <span className="rank" style={{color: this.props.color}}>{this.props.preference}</span>
        <p className="candidate">{this.props.candidate.description}</p>
      </div>
    );
  }
}

class BarGraph extends React.Component {
  render() {
    var colorSchemes = new ColorSchemes();
    var percentages = _u.map(this.props.results, function(rank) {
      return {percent: rank[0].percent, num: rank.length};
    });
    percentages = _u.filter(percentages, function(percent) { return percent.percent > 0; });
    var perc = [];
    _u.each(percentages, function(item, index) {
      var bgColor = colorSchemes.getMixedColor(this.props.color, index, percentages.length);
      var num = item.num;
      while(num){
        perc.push({percent: item.percent, color: bgColor});
        num--;
      }
    }, this)
    return(
      <div className="bar-graph">
        {
          perc.map((percent, index) => {            
            var percentageWidth = {
              width: percent.percent + "%",
              backgroundColor: percent.color
            };
            return(
              <div className={"bar-section divisions-" + percent.num} style={percentageWidth} key={index}></div>
            );
          })
        }
      </div>
    );
  }
}

class Rank extends React.Component {
  render() {
    var colorSchemes = new ColorSchemes();
    var state = ResultsStore.getState();
    var noVotes = this.props.rank[0].percent == 0;
    if (noVotes) {
      var bgColor = "#ccc";
    }
    else {
      var bgColor = colorSchemes.getMixedColor(this.props.color, this.props.num, this.props.total);
    }
    var lightBgColor = Color(bgColor).alpha(0.3);

    var percentageWidth = {
      width: (this.props.rank[0].percent / state.results[0][0].percent * 100) + "%",
      backgroundColor: bgColor
    };
    return (
      <li style={{borderBottomColor: lightBgColor}} className={(noVotes) ? "no-percentage" : ""}>
        {          
          this.props.rank.map((candidate, index) => {
            return <Item candidate={candidate} color={bgColor} key={index} />
          })          
        }
        <div className="percentage-container">
          <span className="percentage-bar" style={percentageWidth}></span>
        </div>
        <span className="percent" style={{color: bgColor}}>{Math.round(this.props.rank[0].percent * 100) / 100}%</span>
      </li>
    );
  }
}

class Respondent extends React.Component {
  render() {
    var colorSchemes = new ColorSchemes();    
    return (
      <li>
        <Link to={'/ballot/' + this.props.respondent._id} className="panel button small">
          <div className="controls left">
            <span className="symbol">
              <EditSymbol />
            </span>
          </div>
          {this.props.respondent.respondent}:
        </Link>         
        {            
          _u.sortBy(this.props.respondent.selections, "preference").map((selection, index) => {
            var color = colorSchemes.getMixedColor(this.props.color, index, this.props.respondent.selections.length);
            return (                
              <InlineItem candidate={selection._candidate} color={color} preference={selection.preference} key={index} />
            );
          })
        }
      </li>
    );
  }
}

class Results extends React.Component {

  constructor(props) {
    super(props);
    this.state = ResultsStore.getState();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    ResultsStore.listen(this.onChange);
    if (this.props.params.token != undefined) {
      ResultsActions.getQuiry(this.props.params.token);
      var token = this.props.params.token;
      // Need to stop this interval when navigating away from page
      // setInterval(function() { 
      //   if (document.visibilityState == "visible") {
      //     ResultsActions.getQuiry(token);
      //   }
      // }, 30000);
    }
    if (this.props.location.state) {
      this.state.status.exceptionClass = this.props.location.state.class;
      this.state.status.exceptionMessage = this.props.location.state.message;
      this.context.router.replace({
        pathname: '/results/' + this.props.params.token,
        state: { class: "", message: "" }
      })
    }
  }

  componentWillUnmount() {
    ResultsStore.unlisten(this.onChange);
    alt.recycle(ResultsStore);
  }

  onChange(state) {
    this.setState(state);
  }

  render() {
    if (this.state.token) {
    const meta = {
      title: 'Voting results for: ' + this.state.description + ' - Quiry',
      description: 'See the full ranking of favorites and winners for this poll in real time.',
      canonical: 'https://www.quiry.com/results/' + this.state.token,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'rank,ranked,vote,voting,poll,polls,survey,results,favorites,ranking,win,winners,real time,top,choices'
        }
      },
      auto: {
        ograph: true
      }
    };
    var totalWithPercent = _u.filter(this.state.results, function(result){return result[0].percent > 0}).length;

    return (
      <div>
      <DocumentMeta {...meta} extend />
      <Header color={this.state.color.name} status={this.state.status} />
        <main role="main" className={"colorscheme-" + this.state.color.name}>
          <header>
            <h2>{this.state.description}</h2>
          </header>
          {(() => {
            if (this.state.respondents.length == 0) {
              return (
                <section className="no-votes">
                  <p>No votes have been cast yet.</p>
                  <Link to={'/vote/' + this.state.token} className="panel button">
                    <div className="controls left">
                      <span className="symbol">
                        <CheckSymbol />
                      </span>
                    </div>
                    Vote
                  </Link>
                </section>
              );
            }
          })()}
          <section className="candidates">
            <BarGraph results={this.state.results} color={this.state.color.name} />
            <ul className="results">
              {
                this.state.results.map((rank, index) => {
                  return <Rank rank={rank} num={index} color={this.state.color.name} total={totalWithPercent} key={index} />
                })
              }
            </ul>
          </section>          
          {(() => {
            if (this.state.respondents.length > 0) {
              return (
                <section className="last">
                  <span className="section-header">More Info:</span>
                  <p><strong>{this.state.respondents.length} {(this.state.respondents.length == 1) ? "vote" : "votes"}</strong> cast.</p>
                  <p>Last vote cast <strong>{this.state.lastVoteTime}</strong>.</p>
                  <p>                    
                    {
                      this.state.respondents.map((respondent, index) => {
                        return (
                          <span key={index}>
                            <Link to={'/ballot/' + respondent._id} >
                              <strong>{respondent.respondent}</strong>
                            </Link>
                            {(() => {
                              if (this.state.respondents.length == (index + 2)) {
                                return(<span>, and </span>);
                              }
                              else if (this.state.respondents.length != (index + 1)) {
                                return(<span>, </span>);
                              }
                            })()}
                          </span>
                        );
                      })
                    }
                     have voted.
                  </p>
                  <Collapse style={{display: "none"}}>
                  <Panel header="Show all votes" headerClass="rc-header">
                  <ul className="ballot-list">
                    {
                      this.state.respondents.map((respondent, index) => {
                        return <Respondent respondent={respondent} color={this.state.color.name} key={index} />;
                      })
                    }
                  </ul>
                  </Panel>
                  </Collapse>
                </section>
              );
            }
          })()}
          <section className="padding">
            <Link to={'/vote/' + this.state.token} className="panel button secondary">
              <div className="controls left">
                <span className="symbol">
                  <CheckSymbol />
                </span>
              </div>
              Cast a Vote
            </Link>
            <Link to={'/new/'} className="panel button secondary">
              <div className="controls left">
                <span className="symbol">
                  <AddSymbol />
                </span>
              </div>
              Create a New Poll
            </Link>
          </section>
        </main>
        <Footer footnote={"Results calculated using the Borda Count method."} />
      </div>
    );
    }
    else {
      return (
        <div>Loading</div>
      );
    }
  }
}
Results.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Results;
