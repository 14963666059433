import React from 'react';
import alt from '../alt';
import _u from 'underscore';
import QuiryStore from '../stores/QuiryStore';
import QuiryActions from '../actions/QuiryActions';
import Textarea from 'react-textarea-autosize';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import NotFound from './NotFound';
import DocumentMeta from 'react-document-meta';
import Color from 'color';
import { AddSymbolThin,
  RemoveSymbol,
  RemoveSymbolThin,
  SaveSymbol,
  CheckSymbol,
  EditSymbol,
  WarningSymbol
  } from './Symbols';
import {Link} from 'react-router';
import ColorSchemes from '../modules/ColorSchemes';

class ChoicesList extends React.Component {
  render() {
    var colorSchemes = new ColorSchemes();
    return (
      <ul>
        {
          this.props.candidates.map((candidate, index) => {
            var bgColor = colorSchemes.getMixedColor(this.props.color, index, this.props.candidates.length)
            return <Candidate candidate={candidate} disabled={this.props.disabled} key={index} color={bgColor} />;
          })
        }
      </ul>
    )
  }
}

class Candidate extends React.Component {
  removeCandidate(event) {
    event.preventDefault();
    var el = event.currentTarget;
    if (!el.classList.contains("disabled")) {
      QuiryActions.removeCandidate(el.getAttribute('data-id'));
    }
  }
  setFocus(event) {
    event.currentTarget.parentElement.classList.add('focused');
  }
  unsetFocus(event) {
    event.currentTarget.parentElement.classList.remove('focused');
  }
  render() {
    return (
      <li className={ (this.props.disabled) ? "textarea disabled" : "textarea" }>
        <div className="controls left">
          <span data-id={this.props.candidate.id} onClick={this.removeCandidate} className={ (this.props.disabled) ? "disabled symbol" : "symbol" }>
            <RemoveSymbolThin />
          </span>
        </div>
        <Textarea
          maxLength="3000"
          name=""
          placeholder=""
          data-id={this.props.candidate.id}
          value={this.props.candidate.description}
          disabled={this.props.disabled}
          onFocus={this.setFocus}
          onBlur={this.unsetFocus}
          onChange={QuiryActions.updateCandidate} />
        <div className="underline" style={{ backgroundColor: this.props.color }}></div>
        <div className="error-message"></div>
      </li>
    );
  }
}

class ColorItem extends React.Component {
  selectColor(event) {
    event.preventDefault();
    QuiryActions.setColorScheme(event.currentTarget.getAttribute('data-name'));
  }
  render() {
    return (
      <li data-name={this.props.scheme.name} onClick={this.selectColor} className={this.props.scheme.selected ? "color-panel colorselect-" + this.props.scheme.name + " selected" : "color-panel colorselect-" + this.props.scheme.name }>
        <span className="symbol">
          <CheckSymbol />
        </span>
      </li>
    );
  }
}

class Quiry extends React.Component {

  constructor(props) {
    super(props);
    this.state = QuiryStore.getState();
    this.onChange = this.onChange.bind(this);
    this.submitting = false
  }

  componentWillMount() {
    if(this.props.location.pathname == "/new") {
      alt.recycle(QuiryStore);
    }
  }

  componentDidMount() {
    QuiryStore.listen(this.onChange);
    if (this.props.params.id != undefined) {
      QuiryActions.getQuiry(this.props.params.id);
    }
    else if (this.props.location.state) {
      this.state.description = this.props.location.state.value;
      QuiryActions.setDescription(this.state.description);
      this.context.router.replace({
        pathname: '/new',
        state: { class: "", message: "", value: "" }
      })
    }
  }

  componentWillReceiveProps(next) {
    if((next.route.path == "/new")&&((this.state.id != "")||(this.state.status.notFound))) {
      alt.recycle(QuiryStore);
    }
    else if(next.params.id != undefined) {
      QuiryActions.getQuiry(next.params.id);
    }
  }

  componentWillUnmount() {
    QuiryStore.unlisten(this.onChange);
    alt.recycle(QuiryStore);
  }

  setFocus(event) {
    var el = event.currentTarget;
    while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
    el.classList.add('focused');
  }
  unsetFocus(event) {
    var el = event.currentTarget;
    while ((el = el.parentElement) && !el.classList.contains('focus-parent'));
    el.classList.remove('focused');
  }
  onChange(state) {
    this.setState(state);
  }

  addCandidateClick(event) {
    event.preventDefault();  
    var panel = event.currentTarget;
    if (!panel.classList.contains('disabled')) {
      var symbol = panel.firstChild.firstChild;
      panel.classList.add("adding");
      symbol.classList.add("remove");
      window.setTimeout(function() {
        panel.classList.remove("adding");
        symbol.classList.remove("remove");
        QuiryActions.addCandidate();
      }, 300);
    }
  }

  checkLength(el, length) {
    var valid = true;
    if (length === 0) {
      el.classList.add('error');
      el.querySelector('.error-message').innerHTML = "Can't be blank";
      valid = false;
    }
    else if (length > 300) {
      el.classList.add('error');
      el.querySelector('.error-message').innerHTML = "Exceeds 300 character limit";
      valid = false;
    }
    return valid;
  }

  checkEmail(input, container) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valid = re.test(input.value);
    if (input.value == "") {
      valid = true;
    }
    if (!valid) {
      container.classList.add('error');
      container.querySelector('.error-message').innerHTML = "Invalid email";
    }
    return valid;
  }

  checkDupes(choices) {
    var prevChoices = [];
    var valid = true;
    _u.each(choices, function(choice, i) {
      var value = choice.querySelector('textarea').value;
      if (_u.contains(prevChoices, value)) {
        choice.classList.add('error');
        choice.querySelector('.error-message').innerHTML = "Duplicate choice";
        valid = false;
      }
      else {
        prevChoices.push(value);
      }
    });
    return valid;
  }

  validateForm() {
    var valid = true;
    var description = document.getElementById('quiry-description');
    var descLength = description.querySelector('textarea').value.length;
    var choices = document.querySelectorAll('.choices-list .textarea');
    var email = document.getElementById('email-input');
    var emailContainer = email.parentElement.parentElement;
    if (!this.checkLength(description, descLength)) { valid = false }
    _u.each(choices, function(choice, i) {
      var choiceLength = choice.querySelector('textarea').value.length;
      if (!this.checkLength(choice, choiceLength)) { valid = false }
    }.bind(this));
    if (!this.checkEmail(email, emailContainer)) { valid = false }
    if (valid) {
      if (!this.checkDupes(choices)) { valid = false }
    }
    return valid;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.submitting) {
      this.submitting = true;
      if (this.validateForm()) {
        QuiryActions.submitQuiry({
          router: this.context.router,
          state: this.state
        });
      }
      else {
        QuiryActions.setStatus({exceptionClass: 'error', exceptionMessage: 'There are problems with your quiry'});
        this.submitting = false;
      }
    }
  }

  deleteQuiry(event) {
    event.preventDefault();
    QuiryActions.deleteQuiry({ id: this.state.id, router: this.context.router });
  }

  render() {
    if (this.state.status.notFound) {
      return (
        <NotFound />
      );
    }
    else if ((this.props.route.path == "/edit/:id")&&(!this.state.id)) {
      return (
        <div>Loading</div>
      );
    }
    else {
    const meta = (this.state.id) ? {
        title: 'Edit Poll - Quiry',
        description: 'Update your poll.',
        canonical: 'https://www.quiry.com/edit/' + this.state.id,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'rank,ranked,vote,voting,poll,polls,survey,edit,update'
          }
        },
        auto: {
          ograph: true
        }
      } : {
        title: 'New Poll - Quiry',
        description: 'Create a new poll to find your group\'s preferences on anything.',
        canonical: 'https://www.quiry.com/new',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'rank,ranked,vote,voting,poll,polls,survey,new,create'
          }
        },
        auto: {
          ograph: true
        }
      };
    return (
      <div>
        <DocumentMeta {...meta} extend />
        <Header status={this.state.status} color={this.state.color.name} />
        <main role="main" className={"colorscheme-" + this.state.color.name}>
          <form onSubmit={this.handleSubmit.bind(this)}>
            {(() => {
              if (this.state.hasVotes) {
                return (
                  <section style={{"paddingTop": "20px"}}>
                    <p>
                      Some of this quiry's contents are not editable because voting has started.
                    </p>
                  </section>
                );
              }
            })()}
            <section>            
              <fieldset>
                <label id="quiry-description" className={ (this.state.hasVotes) ? "textarea focus-parent primary disabled" : "textarea focus-parent primary" }>Question:
                <Textarea
                  maxLength="300"
                  value={this.state.description}
                  disabled={this.state.hasVotes}
                  onFocus={this.setFocus}
                  onBlur={this.unsetFocus}
                  onChange={QuiryActions.updateDescription} />
                  <div className="underline"></div>
                  <div className="error-message"></div>
                </label>
              </fieldset>
              <fieldset>
                <legend>Choices:</legend>                
                <div className="choices-list">
                  <ChoicesList color={this.state.color.name} candidates={this.state.candidates} disabled={this.state.hasVotes} />                  
                  <a href="#" onClick={this.addCandidateClick} className={ (this.state.hasVotes) ? "disabled" : "" }>
                    <div className="controls left">
                      <span className="symbol">
                        <AddSymbolThin />
                      </span>
                    </div>
                    Add Another
                  </a>
                </div>
              </fieldset>
              <fieldset>
                <div className="input focus-parent">
                  <label htmlFor="email-input">Your Email:</label>
                  <span className="input-wrapper"><input id="email-input" type="email" value={this.state.email} onFocus={this.setFocus} onBlur={this.unsetFocus} onChange={QuiryActions.updateEmail} /></span>
                  <div className="underline"></div>
                  <div className="error-message"></div>
                  <div className="info">Optional (For alerts about your poll.)</div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Color Scheme:</legend>
                <ul className="color-select-list">
                  {
                    this.state.colors.map((scheme, index) => {
                      return <ColorItem scheme={scheme} key={index} />;
                    })
                  }
                </ul>
              </fieldset>
              <fieldset>
                
                {(() => {
                  if (this.state.id) {
                    return (
                      <div>
                        <button type='submit' className="button panel primary">
                          <div className="controls left">
                            <span className="symbol">
                              <SaveSymbol />
                            </span>
                          </div>
                          Update
                        </button>
                        <a href="#" className="button panel secondary danger" data-id={this.state.id} onClick={this.deleteQuiry.bind(this)}>
                          <div className="controls left">
                            <span className="symbol">
                              <RemoveSymbol />
                            </span>
                          </div>
                          Delete
                        </a>
                        {(() => {
                          if (this.state.edited) {
                            return (
                              <Link to={'/manage/' + this.state.id} className="panel button secondary warning">
                                <div className="controls left">
                                  <span className="symbol">
                                    <WarningSymbol />
                                  </span>
                                </div>
                                Discard Changes
                              </Link>
                            );
                          }
                          else {
                            return (
                              <Link to={'/manage/' + this.state.id} className="panel button secondary safe">
                                <div className="controls left">
                                  <span className="symbol">
                                    <CheckSymbol />
                                  </span>
                                </div>
                                Done Editing
                              </Link>
                            );
                          }
                        })()}                                              
                      </div>
                    );
                  }
                  else {
                    return (
                      <button type='submit' className="button panel primary">
                        <div className="controls left">
                          <span className="symbol">
                            <SaveSymbol />
                          </span>
                        </div>
                        Save
                      </button>
                    );
                  }
                })()}

              </fieldset>
            </section>
          </form>
        </main>
        <Footer />
      </div>
    );
    }
  }
}
Quiry.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default Quiry;
