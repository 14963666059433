import alt from '../alt';
import _u from 'underscore';
import request from 'superagent';

class QuiryActions {
  constructor() {
    this.generateActions(
      'updateDescription',
      'updateCandidate',
      'updateEmail',
      'getQuirySuccess',
      'getQuiryFail',
      'postQuiryFail',
      'postQuirySuccess',
      'putQuirySuccess',
      'deleteQuirySuccess',
      'deleteQuiryFail',
      'setStatus',
      'resetStatus'
    );
  }
  addCandidate() {
    this.dispatch();
  }
  removeCandidate(index) {
    this.dispatch(index);
  }
  setDescription(value) {
    this.dispatch(value);
  }
  setColorScheme(name) {
    this.dispatch(name);
  }
  submitQuiry(quiryInput) {
    var candidatesArray = [];
    quiryInput.state.candidates.forEach((candidate) => {
      candidatesArray.push({description: candidate.description});
    });

    var quiryData = { description: quiryInput.state.description, published: true, candidates: candidatesArray, colorscheme: quiryInput.state.color.name, email: quiryInput.state.email };

    var requestType = (quiryInput.state.id == "" ? 'POST' : 'PUT');
    var requestURL = (quiryInput.state.id == "" ? '/api/quiries' : '/api/quiries/' + quiryInput.state.id);

    request(requestType, requestURL)
      .send(quiryData)
      .end(function(err, res){
        if (err || !res.ok) {
          this.actions.postQuiryFail(res.body.data);
        } else {
          if (requestType == 'POST') {

            // make send mail request
            if (quiryInput.state.email != "") {
              request('POST', '/api/mail')
                .send({ email: quiryInput.state.email, description: quiryInput.state.description, id: res.body.data._id })
                .end(function(err, res){
                });
            }

            this.actions.postQuirySuccess({data: res.body.data, router: quiryInput.router});
            
          }
          else if (requestType == 'PUT') {
            this.actions.putQuirySuccess({data: res.body.data});
          }
        }
      }.bind(this));

  }
  getQuiry(quiryId) {
    request
      .get('/api/quiries/' + quiryId)
      .end(function(err, res){
        if (err || !res.ok) {
          this.actions.getQuiryFail(res.body);
        } else {
          this.actions.getQuirySuccess(res.body);
        }
      }.bind(this));
  }
  deleteQuiry(payload) {
    if (window.confirm("Are you sure you want to delete this quiry?")) {
      request
        .del('/api/quiries/' + payload.id)
        .end(function(err, res){
          if (err || !res.ok) {
            this.actions.deleteQuiryFail(res.body);
          } else {
            this.actions.deleteQuirySuccess({data: res.body, router: payload.router});
          }
        }.bind(this));
    }
  }
}

export default alt.createActions(QuiryActions);
