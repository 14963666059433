import React from 'react';

class UpSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="up-symbol" fill="#fff">
          <path d="M8,3.83370848 L8,17 L10,17 L10,3.82314577 L16.0763492,9.89949494 L17.4905627,8.48528137 L9.00528136,9.76996262e-15 L8.29817457,0.707106781 L0.519999981,8.48528137 L1.93421354,9.89949494 L8,3.83370848 Z"></path>
        </g>
      </svg>
    );
  }
}
class DownSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="down-symbol" fill="#fff">
          <path d="M8,4.83370848 L8,18 L10,18 L10,4.82314577 L16.0763492,10.8994949 L17.4905627,9.48528137 L9.00528136,1 L8.29817457,1.70710678 L0.519999981,9.48528137 L1.93421354,10.8994949 L8,4.83370848 Z" transform="translate(9.005281, 9.500000) rotate(-180.000000) translate(-9.005281, -9.500000)"></path>
        </g>
      </svg>
    );
  }
}
class SortSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="sort-symbol" fill="#fff">
          <polygon points="14.7,5.7 10.4,1.4 9,0 7.6,1.4 3.3,5.7 4.8,7.1 9,2.8 13.2,7.1 "/>
          <polygon points="3.3,12.3 7.6,16.6 9,18 10.4,16.6 14.7,12.3 13.2,10.9 9,15.2 4.8,10.9 "/>
          <rect x="8" y="2" width="2" height="14"/>
        </g>
      </svg>
    );
  }
}
class AddSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="add-symbol" fill="#fff">
          <path d="M10,8 L10,1 L8,1 L8,8 L1,8 L1,10 L8,10 L8,17 L10,17 L10,10 L17,10 L17,8 L10,8 Z"></path>
        </g>
      </svg>
    );
  }
}
class AddSymbolThin extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="add-symbol-thin" fill="#000000">
          <path d="M10,10 L17,10 L17,9 L10,9 L10,2 L9,2 L9,9 L2,9 L2,10 L9,10 L9,17 L10,17 L10,10 Z"></path>
        </g>
      </svg>
    );
  }
}
class RemoveSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="remove-symbol" fill="#fff">
          <path d="M10,8 L10,1.0658141e-14 L8,1.0658141e-14 L8,8 L4.88498131e-15,8 L4.88498131e-15,10 L8,10 L8,18 L10,18 L10,10 L18,10 L18,8 L10,8 Z" transform="translate(9.000000, 9.000000) rotate(45.000000) translate(-9.000000, -9.000000)"></path>
        </g>
      </svg>
    );
  }
}
class RemoveSymbolThin extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="remove-symbol-thin" fill="#000">
          <path d="M10,9 L10,1 L9,1 L9,9 L1,9 L1,10 L9,10 L9,18 L10,18 L10,10 L18,10 L18,9 L10,9 Z" id="Combined-Shape" transform="translate(9.500000, 9.500000) rotate(-315.000000) translate(-9.500000, -9.500000) "></path>
        </g>
      </svg>
    );
  }
}
class SaveSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="save-symbol" fill="#fff">
          <path d="M10,10.1821356 L13.2494948,6.93264074 L14.6637084,8.34685431 L9.00685415,14.0037086 L8.29974737,13.2966018 L3.3499999,8.34685431 L4.76421347,6.93264074 L8,10.1684273 L8,0 L10,0 L10,10.1821356 Z"></path>
          <path d="M18,17 L18,18 L0,18 L0,17 L0,14 L2,14 L2,16 L16,16 L16,14 L18,14 L18,17 Z"></path>
        </g>
      </svg>
    );
  }
}
class LeaderboardSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="leaderboard-symbol" fill="#fff">
          <path d="M8,2 L11,2 L11,17 L8,17 L8,2 Z M13,12 L16,12 L16,17 L13,17 L13,12 Z M3,7 L6,7 L6,17 L3,17 L3,7 Z"></path>
        </g>
      </svg>
    );
  }
}
class CheckSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="check-symbol" fill="#fff">
          <path d="M10.7781746,11.7781746 L4.77817459,11.7781746 L4.77817459,13.7781746 L11.7781746,13.7781746 L12.7781746,13.7781746 L12.7781746,-0.221825407 L10.7781746,-0.221825407 L10.7781746,11.7781746 Z" id="Combined-Shape" transform="translate(8.778175, 6.778175) rotate(-315.000000) translate(-8.778175, -6.778175) "></path>
        </g>
      </svg>
    );
  }
}
class EditSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="edit-symbol" fill="#fff">
          <polygon id="Path-2" points="11.897625 4.82999992 13.3121185 6.24438477 4.83334351 14.7307892 2.70776367 15.4329224 3.41421509 13.3076935"></polygon>
          <rect id="Rectangle-3" transform="translate(14.729663, 3.412292) rotate(-315.000000) translate(-14.729663, -3.412292) " x="13.7296631" y="2.41229248" width="2" height="2"></rect>
        </g>
      </svg>
    );
  }
}
class WarningSymbol extends React.Component {
  render() {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g className="warning-symbol" fill="#fff">
          <path d="M8.99759526,4.88000011 L3.73205081,14.0001906 L14.2631397,14.0001906 L8.99759526,4.88000011 Z M17.7272413,16.0001906 L15.9951905,16.0001906 L2,16.0001906 L0.267949192,16.0001906 L8.99759526,0.880000114 L17.7272413,16.0001906 Z"></path>
        </g>
      </svg>
    );
  }
}
class FBLogo extends React.Component {
  render() {
    return(
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill="#3C5A99" d="M17,18c0.5,0,1-0.4,1-1V1c0-0.5-0.4-1-1-1H1C0.4,0,0,0.4,0,1v16c0,0.5,0.4,1,1,1H17z"/>
          <path fill="#fff" d="M12.4,18v-7h2.3l0.4-2.7h-2.7V6.6c0-0.8,0.2-1.3,1.3-1.3l1.4,0V2.8c-0.2,0-1.1-0.1-2.1-0.1
            C11,2.7,9.6,4,9.6,6.3v2H7.3V11h2.3v7H12.4z"/>
        </g>
      </svg>
    );
  }
}
class QuiryLogo extends React.Component {
  render() {
    return(
      <svg width="39" height="34" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 34">
        <g className="circle" fill="#777">
          <path d="M7.8,26.2c-5.1-5.1-5.1-13.3,0-18.4s13.3-5.1,18.4,0c3.8,3.8,4.8,9.4,2.8,14.1l3,3c3.4-6.4,2.4-14.6-3-20
            C22.4-1.7,11.6-1.7,5,5s-6.6,17.4,0,24c5.4,5.4,13.6,6.4,20,3l-3-3C17.2,30.9,11.6,30,7.8,26.2z"/>
        </g>
        <g>
          <path className="line-1" d="M36.8,34l1.4-1.4L25.5,19.9c-0.2,0.7-0.5,1.3-0.9,1.9L36.8,34z"/>
          <path className="line-2" d="M22.6,24l9.9,9.9l1.4-1.4L24,22.6c-0.2,0.3-0.4,0.5-0.7,0.7C23.1,23.6,22.9,23.8,22.6,24z"/>
          <path className="line-3" d="M28.3,34l1.4-1.4l-7.9-7.9c-0.6,0.4-1.3,0.7-1.9,0.9L28.3,34z"/>
        </g>
      </svg>
    );
  }
}

module.exports = {
  UpSymbol,
  DownSymbol,
  SortSymbol,
  AddSymbol,
  AddSymbolThin,
  RemoveSymbol,
  RemoveSymbolThin,
  SaveSymbol,
  LeaderboardSymbol,
  CheckSymbol,
  EditSymbol,
  WarningSymbol,
  FBLogo,
  QuiryLogo
}
