import alt from '../alt';
import QuiryManageActions from '../actions/QuiryManageActions';
import Color from 'color';
import ColorSchemes from '../modules/ColorSchemes';
import _u from 'underscore';
import timeago from 'timeago.js';

class QuiryManageStore {
  constructor() {
    this.colorSchemes = new ColorSchemes();
    this.bindActions(QuiryManageActions);
    this.description = "";
    this.candidates = [];
    this.id = undefined;
    this.token = undefined;    
    this.results = [];
    this.respondents = [];
    this.created = undefined;
    this.updated = undefined;
    this.lastVoteTime = '';
    this.color = this.colorSchemes.getScheme("moon");
    this.status = {
      pageTitle: 'Manage Poll',
      exceptionClass: '',
      exceptionMessage: ''
    };
  }

  onGetQuirySuccess(data) {
    if (!this.colorSchemes.getAllSchemes) {
      this.colorSchemes = new ColorSchemes();
    }
    var candidatesIndex = data.data.quiry.candidates;
    var dateString = (data.data.ballots.length > 0) ? data.data.ballots[data.data.ballots.length - 1].modifiedDate : "";
    var timeAgo = timeago();
    var lastVoteDate = new Date(dateString);
    var createdDate = new Date(data.data.quiry.createdDate);
    var modifiedDate = new Date(data.data.quiry.modifiedDate);
    var colorS = this.colorSchemes.getScheme(data.data.quiry.colorscheme)
    this.lastVoteTime = timeAgo.format(lastVoteDate);

    this.id = data.data.quiry._id;
    this.description = data.data.quiry.description;
    this.token = data.data.quiry.token;
    this.respondents = data.data.ballots;
    this.created = timeAgo.format(createdDate);
    this.updated = timeAgo.format(modifiedDate);
    this.candidates = candidatesIndex;
    if (colorS) {
      this.color = colorS;
    }

    if (this.respondents.length == 0) {
      _u.each(data.data.quiry.candidates, function(candidate, index){
        candidate.rank = "×";
      });
      this.results.push(data.data.quiry.candidates);
    }
    else {
      var resultsData = [];
      var sortedCandidates = _u.sortBy(data.data.quiry.candidates, 'rank');
      var ranks = _u.uniq(_u.pluck(sortedCandidates, 'rank'));
      _u.each(ranks, function(val, index){
        var candidatesAtRank = _u.where(data.data.quiry.candidates, {rank: val});
        if (candidatesAtRank.length > 1) {
          _u.each(candidatesAtRank, function(candidate, i){
            if (candidate.percent == 0) {
              candidate.rank = "×";
            }
            else if (i > 0) {
              candidate.rank = "=";
            }
          });
        }
        else if (candidatesAtRank[0].percent == 0) {
          candidatesAtRank[0].rank = "×";
        }
        resultsData.push(candidatesAtRank);
      });
      // console.log(resultsData)
      this.results = resultsData;
    }
  }

}

export default alt.createStore(QuiryManageStore);
