import React from 'react';

class InfoBanner extends React.Component {
  componentWillReceiveProps() {
  }
  render() {
    return (
      <div className="info-banner">
        {this.props.status.pageTitle}
        {(() => {
          if (this.props.status.exceptionMessage != "") {
            return (
              <div className={"message " + this.props.status.exceptionClass }>
                {this.props.status.exceptionMessage}
              </div>
            );
          }
        })()}
      </div>
    )
  }
}

export default InfoBanner;
