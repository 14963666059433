import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {Link} from 'react-router';

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <Header status={{
            pageTitle: 'Poll Not Found',
            exceptionClass: '',
            exceptionMessage: ''
          }} color="pacific" />
        <main role="main" className="colorscheme-error">
          <section>
            <p>We couldn't find the poll you're looking for.</p>
            <p>Would you like to <Link to={'/new'}>create a new one</Link>?</p>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}

export default NotFound;
