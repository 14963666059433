import alt from '../alt';
import _u from 'underscore';
import ResultsActions from '../actions/ResultsActions';
import Color from 'color';
import ColorSchemes from '../modules/ColorSchemes';
import timeago from 'timeago.js';

class ResultsStore {
  constructor() {
    this.colorSchemes = new ColorSchemes();
    this.bindActions(ResultsActions);
    this.description = '';
    this.token = '';
    this.results = [];
    this.respondents = [];
    this.color = this.colorSchemes.getScheme("moon");
    this.total = 0;
    this.lastVoteTime = '';
    this.status = {
      pageTitle: 'Results',
      exceptionClass: '',
      exceptionMessage: ''
    };

    this.bindListeners({

    });
  }

  onGetQuirySuccess(data) {
    if (!this.colorSchemes.getAllSchemes) {
      this.colorSchemes = new ColorSchemes();
    }
    var dateString = (data.data.ballots.length > 0) ? data.data.ballots[data.data.ballots.length - 1].modifiedDate : "";
    var timeagoInstance = timeago();
    var resultsData = [];
    var lastVoteDate = new Date(dateString);
    var colorS = this.colorSchemes.getScheme(data.data.quiry.colorscheme);
    this.description = data.data.quiry.description;
    this.token = data.data.quiry.token;
    this.respondents = data.data.ballots;
    if (colorS) {
      this.color = colorS;
    }
    this.lastVoteTime = timeagoInstance.format(lastVoteDate);

    if (this.respondents.length == 0) {
      _u.each(data.data.quiry.candidates, function(candidate, index){
        candidate.rank = "×";
      });
      this.results.push(data.data.quiry.candidates);
    }
    else {
      var resultsData = [];
      var sortedCandidates = _u.sortBy(data.data.quiry.candidates, 'rank');
      var ranks = _u.uniq(_u.pluck(sortedCandidates, 'rank'));
      _u.each(ranks, function(val, index){
        var candidatesAtRank = _u.where(data.data.quiry.candidates, {rank: val});
        if (candidatesAtRank.length > 1) {
          _u.each(candidatesAtRank, function(candidate, i){
            if (candidate.percent == 0) {
              candidate.rank = "×";
            }
            else if (i > 0) {
              candidate.rank = "=";
            }
          });
        }
        else if (candidatesAtRank[0].percent == 0) {
          candidatesAtRank[0].rank = "×";
        }
        resultsData.push(candidatesAtRank);
      });
      this.results = resultsData;
    }
  }

}

export default alt.createStore(ResultsStore);
